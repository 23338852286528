/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Pagination from 'ui/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersList, getFilterParams } from 'redux/reducers/userManagement/selectors';
import { getUsersRequest, setFilterParams } from 'redux/reducers/userManagement/reducer';
import { IUsersListRequestPayload } from 'redux/reducers/userManagement/types';
import {
	USER_SEARCH_ARRAY,
	USER_SEARCH_TEXT_ARRAY,
	USER_STATUS_ARRAY,
	USER_STATUS_TEXT_ARRAY,
	USER_LEVEL_ARRAY,
} from 'redux/reducers/userManagement/constants';
import UserManagementList from './UserManagementList';
import SearchBar from '../SearchBar';
import { UserFilter } from './filters/UserFilter';
/* eslint-disable */
// ==========================================:
const UserManagement: FC = () => {
	const usersList = useSelector(getUsersList);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const { search, state } = useLocation();
	const { page, text, field } = queryString.parse(search);
	const [searchField, setSearchField] = useState(field ? String(field) : 'user_id');
	const [dropdownArray, setDropdownArray] = useState<{
		dropdownArray: string[];
		dropdownTextArray: string[];
	}>({ dropdownArray: ['all'], dropdownTextArray: ['all'] });
	const [typeSearchDropdown, setTypeSearchDropdown] = useState(false);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const filterParams = useSelector(getFilterParams);

	const { start_date: startDate, end_date: endDate } = filterParams || {};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';
		if (text) {
			verifyText = String(text).trim();
		}
		const searchParams =
			verifyText.length > 0
				? `?page=${String(pageNumber)}${verifyText && `&text=${verifyText}&field=${searchField}`}`
				: `?page=${String(pageNumber)}`;

		const params: IUsersListRequestPayload =
			verifyText.length > 0
				? {
						current_page: pageNumber || 1,
						search_field: searchField,
						search_value: verifyText,
						start_date: startDate || null,
						end_date: endDate || null,
				  }
				: {
						current_page: pageNumber || 1,
						start_date: startDate || null,
						end_date: endDate || null,
				  };
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getUsersRequest(params));
	};

	const handleSearch = (txt: string) => {
		const changePage = txt !== text ? 1 : page;
		const params = {
			current_page: changePage,
			search_value:
				!txt || searchField === USER_SEARCH_ARRAY[5] || searchField === 'all' ? null : txt,
			search_field: !txt || searchField === 'all' ? null : searchField,
			start_date: startDate || null,
			end_date: endDate || null,
		};

		if (txt.length >= 1 || (searchField === USER_SEARCH_ARRAY[5] && startDate, endDate)) {
			dispatch(getUsersRequest(params));
		} else {
			dispatch(getUsersRequest({ current_page: 1 }));
		}

		history.push({
			pathname: location.pathname,
			search:
				txt.length >= 1
					? `?page=${changePage}&text=${String(txt)}&field=${searchField}`
					: `?page=${1}`,
		});
		setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	};
	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 800);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		const cameFromUser = JSON.parse(sessionStorage.getItem('cameFromUserPage') || 'false');

		if (cameFromUser && !debouncedTerm) {
			if (cameFromUser) {
				setDebouncedTerm(String(text));
				sessionStorage.removeItem('cameFromUserPage'); // Очистити стан після використання
			}
			return;
		}
		handleSearch(term);
	}, [term, startDate, endDate]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const searchFieldHandler = (txt: string) => {
		if (txt === 'status') {
			setDropdownArray({
				dropdownArray: USER_STATUS_ARRAY,
				dropdownTextArray: USER_STATUS_TEXT_ARRAY,
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}

		if (txt === 'level') {
			setDropdownArray({
				dropdownArray: USER_LEVEL_ARRAY,
				dropdownTextArray: USER_LEVEL_ARRAY,
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}
		if (txt === 'kyc_approved') {
			setSearchField(txt);
			setTypeSearchDropdown(false);
			return;
		}

		setSearchField(txt);
		setTypeSearchDropdown(false);
	};

	useEffect(() => {
		return () => {
			dispatch(setFilterParams({ start_date: null, end_date: null }));
		};
	}, [dispatch]);

	return (
		<>
			<div className="title-block">
				<p className="title">User Management</p>
			</div>
			<div className="count-label count-label--filter-between">
				<p>All users ({usersList?.total ?? 0})</p>

				<div className="filter-group">
					<SearchBar
						setDebouncedTerm={setDebouncedTerm}
						debouncedTerm={debouncedTerm}
						searchFieldArray={USER_SEARCH_ARRAY}
						searchField={searchField}
						setSearchField={searchFieldHandler}
						handleClearSearch={handleClearSearch}
						searchFieldTextArray={USER_SEARCH_TEXT_ARRAY}
						typeSearch={typeSearchDropdown ? 'dropdown' : undefined}
						dropdownArray={dropdownArray.dropdownArray}
						dropdownTextArray={dropdownArray.dropdownTextArray}
						inputWidth="155px"
						inputStatus={
							searchField === USER_SEARCH_ARRAY[5] || searchField === USER_SEARCH_ARRAY[6]
						}
						inputEnter="Enter date on calendar"
					/>
					<UserFilter />
				</div>
			</div>

			<UserManagementList users={usersList?.users} handleClearSearch={handleClearSearch} />

			{(usersList?.total ?? 0) > 1 && (
				<Pagination
					pageCount={usersList?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default UserManagement;
